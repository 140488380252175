<template>
  <div>
    <div class="d-flex justify-content-between">
      <div class="textcustom">รายการชำระเงิน</div>
      <div>ยอดชำระเงินรวม ฿ {{ total | numeral("0,0.00") }}</div>
    </div>
    <b-col
      class="px-0"
      v-for="(payment, pindex) in list"
      :key="`${payment.id}-${pindex}`"
    >
      <div class="d-flex mt-2 w-100">
        <div
          @click="$emit('onPaymentSelected', payment, pindex)"
          :class="[
            'd-flex payment-box',
            paymentSelectedIndex == pindex
              ? 'payment-box-active'
              : 'payment-box-unactive'
          ]"
        >
          <div class="w-100 px-2 pb-2 pt-1 f-size-14">
            <div>
              {{ payment.transferDate | moment($formatDate) }} ({{
                payment.transferTime | moment($formatTime)
              }})
            </div>
            <div class="d-flex" v-if="payment.bankAccountId">
              <div>
                <img
                  :src="getBankAccount(payment.bankAccountId).imageUrl"
                  :alt="getBankAccount(payment.bankAccountId).bankName"
                  class="bank-image"
                />
              </div>
              <div class="ml-2 text-gray-2">
                <div>
                  {{ getBankAccount(payment.bankAccountId).accountName }}
                </div>
                <div>
                  {{ getBankAccount(payment.bankAccountId).accountNo }}
                </div>
              </div>
            </div>
            <div class="d-flex" v-else>
              <div class="bank-image"></div>
              <div class="ml-2 text-danger">
                กรุณาระบุธนาคารปลายทาง
              </div>
            </div>
          </div>
          <div
            :class="[
              'd-flex align-items-center justify-content-center',
              paymentSelectedIndex == pindex ? 'bg-purple-1' : 'bg-gray-1'
            ]"
          >
            <div>
              {{ payment.amount | numeral("0,0.00") }}
            </div>
          </div>
        </div>
        <b-button
          variant="link"
          class="text-danger p-0 pl-1 cursor-default"
          type="button"
          v-if="isCanEdit"
        >
          <font-awesome-icon
            class="pointer"
            @click="$emit('onDeleteSlip', payment, pindex)"
            icon="trash"
            title="Delete"
          />
        </b-button>
      </div>
      <span v-if="v.$each.$iter[pindex].$error" class="text-error f-size-14"
        >กรุณากรอกข้อมูลให้ครบถ้วน</span
      >
    </b-col>
  </div>
</template>

<script>
export default {
  props: {
    paymentDetailList: {
      required: true,
      type: Array
    },
    bankAccountList: {
      required: true,
      type: Array
    },
    paymentSelected: {
      required: true,
      type: Object
    },
    v: {
      required: true,
      type: Object
    },
    paymentSelectedIndex: {
      required: false,
      type: Number
    },
    isCanEdit: {
      required: true,
      type: Boolean
    }
  },
  computed: {
    total() {
      let sum = 0;
      this.list.forEach(element => {
        sum += parseFloat(element.amount);
      });
      return sum;
    }
  },
  data() {
    return {
      list: this.paymentDetailList
    };
  },
  methods: {
    getBankAccount(bankAccountId) {
      if (this.bankAccountList) {
        return this.bankAccountList.find(el => el.id == bankAccountId);
      } else {
        return null;
      }
    },
    updateList: async function(newList) {
      this.list = [];
      this.list = await newList;
    }
  }
};
</script>

<style scoped>
.textcustom {
  color: #575757;
  font-size: 15px;
}
.bank-image {
  height: 40px;
  width: 40px;
}
.payment-box-active {
  border: 1px solid rgb(113, 96, 196);
  height: auto !important;
  border-radius: 9px;
  cursor: pointer;
}
.payment-box-unactive {
  border: 1px solid gray;
  height: auto !important;
  border-radius: 9px;
  cursor: pointer;
}
.bg-gray-1,
.bg-purple-1 {
  color: white;
  border-radius: 0px 6px 6px 0px;
  max-width: 120px;
  width: 120px;
}
.bg-purple-1 {
  background-color: rgb(113, 96, 196);
}
.bg-gray-1 {
  background-color: gray;
}
.text-error {
  color: red;
}
.payment-box {
  height: 72px;
}
.payment-amount-box {
  width: 80px;
}
</style>
