<template>
  <div>
    <p class="textcustom">หมายเลขคำสั่งซื้อ: {{ invoiceNo }}</p>
    <p class="textcustom">
      ราคารวม: ฿ {{ orderTotalAmount | numeral("0,0.00") }}
    </p>
    <div class="mt-2 textcustom">
      <b-form-group
        label="ธนาคารปลายทาง"
        v-slot="{ ariaDescribedby }"
        class="mb-2"
      >
        <b-form-radio
          v-for="bank in bankAccountList"
          :key="bank.bankId"
          v-model="paymentSelected.bankAccountId"
          :aria-describedby="ariaDescribedby"
          :name="'bank-' + bank.bankName"
          :value="bank.id"
          :disabled="!isCanEdit"
          class="mb-2"
        >
          <div class="d-flex">
            <img :src="bank.imageUrl" :alt="bank.imageUrl" class="bank-image" />
            <div class="ml-2 f-14">
              <p class="mb-0">{{ bank.accountName }}</p>
              <p class="mb-0">{{ bank.accountNo }}</p>
            </div>
          </div>
        </b-form-radio>
      </b-form-group>
    </div>

    <div>
      <InputText
        textFloat="จำนวนเงิน"
        v-model="paymentSelected.amount"
        class="mr-2"
        placeholder="จำนวนเงิน"
        type="number"
        :isValidate="v.amount.$error"
        isRequired
        :v="v.amount"
        :isDisplay="!isCanEdit"
      />
    </div>
    <div class="picker-container">
      <div class="mt-3">
        <label class="mb-1 textcustom"
          >วันที่โอน <span class="text-danger">*</span></label
        >
        <div class="input-container mr-2">
          <datetime
            :input-style="styleDatetime"
            v-model="paymentSelected.transferDate"
            placeholder="วว / ดด / ปป"
            ref="transferDate"
            value-zone="Asia/Bangkok"
            :disabled="!isCanEdit"
          >
          </datetime>
        </div>
      </div>
      <div class="mt-3">
        <label class="mb-1 textcustom"
          >เวลาที่โอน <span class="text-danger">*</span></label
        >
        <div class="input-container mr-2">
          <datetime
            :input-style="styleDatetime"
            v-model="paymentSelected.transferTime"
            :disabled="!isCanEdit"
            type="time"
            placeholder="ชม. / นาที"
            value-zone="Asia/Bangkok"
            ref="transferTime"
          >
          </datetime>
        </div>
      </div>
    </div>
    <div class="mt-3 mr-2">
      <UploadFile
        textFloat="อัพโหลดภาพ"
        placeholder="กรุณาเลือกไฟล์เพื่ออัพโหลด"
        format="image"
        name="thumbnail"
        :fileName="paymentSelected.imageUrlName"
        :disabled="!isCanEdit"
        text=""
        isRequired
        v-on:onFileChange="onImageChange"
        v-on:delete="onDeleteImage"
        :v="v.imageUrl"
      />
    </div>
    <div class="mt-2">
      <InputTextArea
        class=""
        type="text"
        textFloat="บันทึกเพิ่มเติม"
        placeholder="บันทึกเพิ่มเติม"
        name="text"
        rows="5"
        v-model="paymentSelected.note"
        :disabled="!isCanEdit"
      />
    </div>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import UploadFile from "@/components/inputs/UploadFile";
import InputTextArea from "@/components/inputs/InputTextArea";
import { Datetime } from "vue-datetime";
import { mapGetters } from "vuex";
export default {
  components: {
    InputText,
    UploadFile,
    InputTextArea,
    datetime: Datetime
  },
  props: {
    invoiceNo: {
      required: true,
      type: String
    },
    orderTotalAmount: {
      required: true,
      type: Number
    },
    paymentSelected: {
      required: true,
      type: Object
    },
    bankAccountList: {
      required: true,
      type: Array
    },
    v: {
      required: true,
      type: Object
    },
    orderStatus: {
      required: true,
      type: Number
    },
    isCanEdit: {
      required: true,
      type: Boolean
    }
  },
  data() {
    return {
      styleDatetime: {
        width: "100%",
        border: "none"
      },
      note: ""
    };
  },
  computed: {
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      permission: "getPermission"
    })
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    onImageChange(value, index) {
      this.paymentSelected.imageUrl = "";
      this.paymentSelected.imageUrlName = "";
      if (value) {
        this.paymentSelected.imageUrlName = value.name; //`${value.name}.${value.type.split("/").pop(-1)}`; //value.name;
        var reader = new FileReader();
        reader.readAsDataURL(value);
        reader.onload = () => {
          this.paymentSelected.imageUrl = reader.result;
        };
      }
    },

    onDeleteImage() {}
  }
};
</script>

<style scoped>
.textcustom {
  font-size: 15px;
  color: #575757;
}
.bank-image {
  width: 40px;
  height: 40px;
}
.input-container {
  display: flex;
  padding: 0.5rem;
  border: 1px solid #bcbcbc;
  border-radius: 6px;
  align-items: center;
  justify-content: space-between;
}
::v-deep .custom-input {
  color: #575757;
  background-color: white;
  border: 1px solid #bcbcbc !important;
  border-radius: 8px;
  height: 40px;
  padding: 5px 10px;
}
::v-deep
  .custom-control
  .custom-control-input:checked
  ~ .custom-control-label::before,
::v-deep .custom-radio .custom-control-label::before,
::v-deep .custom-control .custom-control-label::after {
  top: 12px !important;
}
</style>
